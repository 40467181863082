import React from 'react';
import HeroImageSpaceship from '../../assets/images/hero/Spaceship.png';
import HeroImageRift from '../../assets/images/hero/Rift.png';
import HeroImagePlanet from '../../assets/images/hero/Planet.png';

const HeroImage = () => {
    return (
        <div className="hero-image">
            <div className="hero-image-wrapper">
                <img src={HeroImageSpaceship} className="hero-image-spaceship img-fluid" alt="Spaceship" />
                <img src={HeroImageRift} className="hero-image-rift img-fluid" alt="Rift" />
                <img src={HeroImagePlanet} className="hero-image-planet img-fluid" alt="Planet" />
            </div>
        </div>
    );
};

export default HeroImage;
